@font-face {
    font-family: 'Nexon Lv1 Gothic';
    src: url(./NexonLv1.woff) format('woff');
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url(./JetBrainsMono-Light.woff2) format('woff');
    font-weight: lighter;
}
@font-face {
    font-family: 'JetBrains Mono';
    src: url(./JetBrainsMono-Regular.woff2) format('woff');
    font-weight: normal;
}
@font-face {
    font-family: 'JetBrains Mono';
    src: url(./JetBrainsMono-Bold.woff2) format('woff');
    font-weight: bold;
}

html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
}
#root {
    background-color: rgb(243, 246, 249);
}

.center {
    text-align: center;
    width: 100%;
}

.cell-success {
    background-color: #4caf50;
}

.cell-boosted {
    background-color: #42a5f5;
}

.cell-error {
    background-color: #ef5350;
}

.cell-disabled {
    background-color: lightgray;
}

.cell-fixedwidth {
    font-family: 'JetBrains Mono', monospace;
}

h2,
h3,
h4,
h5,
h6 {
    text-align: center;
    font-family: 'Nexon Lv1 Gothic';
    font-weight: bold;
    margin: 0;
}

h2 {
    font-size: 40px;
}

ul.disclaimer {
    font-size: 10px;
    font-family: 'JetBrains Mono', sans-serif;
    color: black;
}
